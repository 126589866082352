import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTiktok,
  FaTwitter,
} from "react-icons/fa";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import "./ContactCard.css";

function ContactCard() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "contacts"), {
        name,
        phone,
        email,
        message,
        timestamp: new Date(),
      });
      setName("");
      setPhone("");
      setEmail("");
      setMessage("");
      setError("");
      alert("Message sent successfully!");
    } catch (e) {
      setError("Error sending message: " + e.message);
    }
  };

  return (
    <Container className="contact-card">
      <Row>
        <Col md={6}>
          <h2>Call Us Today!</h2>
          <p>
            <strong>Opening Hours:</strong> Monday – Friday 8:00 am – 5:00 pm
          </p>
          <p>
            <strong>Address:</strong> Montezuma, GA
          </p>
          <p>
            <strong>CALL US AT: </strong>
            <a href="tel:4782447611" className="contact-link">
              478-244-7611
            </a>
          </p>
          <p>
            <strong>Email: </strong>
            <a href="mailto:outdoorsheds@icloud.com" className="contact-link">
              outdoorsheds@icloud.com
            </a>
          </p>
          <div className="social-icons">
            <a href="http://www.facebook.com/gaoutdoorsheds">
              <FaFacebook />
            </a>
            <a href="https://instagram.com/outdoorsheds?igshid=YmMyMTA2M2Y=">
              <FaInstagram />
            </a>
            <a href="https://www.youtube.com/channel/UClyJkLVvtEMCRe6agrDXw4A">
              <FaYoutube />
            </a>
            <a href="http://www.tiktok.com/@gaoutdoorsheds">
              <FaTiktok />
            </a>
            <a href="https://twitter.com/gaoutdoorsheds">
              <FaTwitter />
            </a>
          </div>
        </Col>
        <Col md={6}>
          <h2>Let's Talk</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Control
                type="text"
                placeholder="Your Name"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formPhoneNumber">
              <Form.Control
                type="text"
                placeholder="Phone Number"
                className="form-control"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Control
                type="email"
                placeholder="Email Address"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Message"
                className="form-control"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Send Request
            </Button>
          </Form>
          {error && <p>{error}</p>}
        </Col>
      </Row>
    </Container>
  );
}

export default ContactCard;
