// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // import firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBXXzH3KQvttQUDq4NdKrlZZu7queLVasc",
  authDomain: "outdoorsheds-d46b2.firebaseapp.com",
  databaseURL: "https://outdoorsheds-d46b2-default-rtdb.firebaseio.com",
  projectId: "outdoorsheds-d46b2",
  storageBucket: "outdoorsheds-d46b2.appspot.com",
  messagingSenderId: "777368689357",
  appId: "1:777368689357:web:ffc0459e8de91983ba2784",
  measurementId: "G-ZJW04EDLY8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
